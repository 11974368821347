
























































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import store from '@/services/store';

@Component({
    components: {
    },
    name: 'c-calc-gkkp-tab'
})
export default class CBubgGkkpSumCalcTbl extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYearProp!: number;

    @Prop({
        required: true,
        default: null
    })
    private curAbpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curDataTypeProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private regionProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curVariantProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curGkkpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private variantEndDateProp!: any | null;

    private progress = 0;
    private collapseAll = true;
    private calcData: any[] = [];
    private totalSum = { value1: 0, value2: 0, value3: 0, limit1: 0,  limit2: 0,  limit3: 0, proc2: 0, proc3: 0 };

    private curGkkp: any | null = null;
    private curYear: number = 0;
    private curAbp: any = null;
    private curDataType: any = null;
    private curRegion: any = null;
    private curVariant: any = null;
    private variantEndDate: any | null = null;

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private chgEvent(params: any) {
        this.curAbp = params.curAbp;
        this.curYear = params.curYear;
        this.curDataType = params.curDataType;
        this.curRegion = params.regionProp;
        this.curVariant = params.curVariant;
        this.curGkkp = params.curGkkp;
        this.variantEndDate = params.variantEndDate;
        this.loadData();
    }

    private mounted() {
        this.curAbp = this.curAbpProp;
        this.curYear = this.curYearProp;
        this.curDataType = this.curDataTypeProp;
        this.curRegion = this.regionProp;
        this.curVariant = this.curVariantProp;
        this.curGkkp = this.curGkkpProp;
        this.variantEndDate = this.variantEndDateProp;
        this.loadData();
    }

    private async loadData() {
        this.calcData = [];
        this.totalSum = { value1: 0, value2: 0, value3: 0, limit1: 0,  limit2: 0,  limit3: 0, proc2: 0, proc3: 0 };
        if (!this.curAbp || !this.curDataType || !this.curYear || !this.curVariant) { return; }
        this.progress = 30;
        const params = { curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.curRegion.code, variant: this.curVariant.variant_uuid, bin: this.curGkkp.bin, usrId: this.usrId, date_b: this.curVariant.date_start, date_e: this.variantEndDate };
        const result = await Promise.all([
            this.loadLimitGkkp(params),
            this.loadTotalGkkp(params)
        ]);
        if (!result[0] || !result[1]) { return; }
        this.createTableData(result);
    }

    // ------------------------ получение дерева ------------------
    private createTableData(dataArr: any[]) {
        const totalArr = dataArr[1];
        const limitArr = this.createGkkpArr(dataArr[0]);
        
        const result: any[] = this.createGkkpArr(totalArr, true);

        for (const elGkkp of result) {
            elGkkp.prgLst.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
            const prgArr: any[] = [];
            for (let i = 0; i < elGkkp.prgLst.length; i++) {
                const elPrg = elGkkp.prgLst[i];
                if (prgArr.length === 0 || prgArr[prgArr.length-1].prg !== elPrg.prg) {
                    this.createPprArr(prgArr);
                    if (prgArr.length) { this.calcTotal(elGkkp.totalSum, prgArr[prgArr.length - 1]); }
                    const obj = { prg: elPrg.prg, name_ru: elPrg.prg_name_ru, collapse: this.collapseAll, limit1: null, limit2: null, limit3: null, pprLst: [elPrg], totalSum: { value1: 0, value2: 0, value3: 0, proc2: null, proc3: null } };
                    this.addLimitToTotal(obj, elGkkp.bin, limitArr);
                    prgArr.push(obj);
                } else {
                    prgArr[prgArr.length - 1].pprLst.push(elPrg);
                }
                if (i === elGkkp.prgLst.length - 1) { 
                    this.createPprArr(prgArr);
                    this.calcTotal(elGkkp.totalSum, prgArr[prgArr.length - 1]);
                }
            }
            elGkkp.prgLst = prgArr;
        }
        this.progress += 10;
        this.addLastLimitToTotal(result, limitArr);
        this.totalSum = { value1: 0, value2: 0, value3: 0, limit1: 0,  limit2: 0,  limit3: 0, proc2: 0, proc3: 0 };
        for (const el of result) {
            this.calcTotal(this.totalSum, el.totalSum);
            this.calcProc(el.totalSum);
        }
        this.calcProc(this.totalSum);
        this.calcData = result;
        this.progress = 100;
    }

    private calcProc(obj: any) {
        if (obj.value1) { obj.proc2 = Math.round(obj.value2/obj.value1 * 1000) / 10; }
        if (obj.value2) { obj.proc3 = Math.round(obj.value3/obj.value2 * 1000) / 10; }
    }

    private createGkkpArr(arr: any[], getVal = false) {
        const result: any[] = [];
        for (const el of arr) {
            if (getVal) this.getValue(el);
            if (result.length === 0 || result[result.length-1].bin !== el.bin) {
                const obj = { bin: el.bin, name_ru: el.bin_name_ru, collapse: this.collapseAll, prgLst: [el], totalSum: { value1: 0, value2: 0, value3: 0, limit1: 0, limit2: 0, limit3: 0, proc2: null, proc3: null } };
                result.push(obj);
            } else {
                result[result.length-1].prgLst.push(el);
            }
        }
        return result;
    }

    private addLimitToTotal(totalObj: any, bin: string,  limitArr: any[]) {
        for (let i = 0; i < limitArr.length; i++) {
            const binObj = limitArr[i];
            if (binObj.bin === bin) {
                for (let j = 0; j < binObj.prgLst.length; j++) {
                    const prgObj = binObj.prgLst[j];
                    if (prgObj.prg === totalObj.prg) {
                        totalObj.limit1 = prgObj.value1;
                        totalObj.limit2 = prgObj.value2;
                        totalObj.limit3 = prgObj.value3;
                        binObj.prgLst.splice(j, 1);
                        if (!binObj.prgLst.length) { limitArr.splice(i, 1); }
                        return;
                    }
                }
                return;
            }
        }
    }

    private addLastLimitToTotal(totalArr: any[], limitArr: any []) {
        let sortBinFl = false;
        for (const binLimit of  limitArr) {
            let binFl = false;
            for (let i = 0; i < totalArr.length; i++) {
                const binTotal = totalArr[i];
                if (binLimit.bin === binTotal.bin) {
                    binFl = true;
                    for (const prgLimit of binLimit.prgLst) {
                        const obj: any = { prg: prgLimit.prg, name_ru: prgLimit.prg_name_ru, limit1: prgLimit.value1, limit2: prgLimit.value2, limit3: prgLimit.value3, totalSum: { value1: 0, value2: 0, value3: 0, proc2: null, proc3: null }, pprLst: [] };
                        for (let s = 1; s < 4; s++) {
                            if (obj[`limit${s}`]) { binTotal.totalSum[`limit${s}`] += obj[`limit${s}`]; }
                        }
                        binTotal.prgLst.push(obj);
                    }
                    binTotal.prgLst.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                    break;
                }
            }
            if (!binFl) {
                const binObj: any = { bin: binLimit.bin, name_ru: binLimit.name_ru, prgLst: [], collapse: this.collapseAll, totalSum: { value1: 0, value2: 0, value3: 0, limit1: 0,  limit2: 0,  limit3: 0, proc2: null, proc3: null, } };
                for (const prgLimit of binLimit.prgLst) {
                    const obj: any = { prg: prgLimit.prg, name_ru: prgLimit.prg_name_ru, limit1: prgLimit.value1, limit2: prgLimit.value2, limit3: prgLimit.value3, totalSum: { value1: 0, value2: 0, value3: 0, proc2: null, proc3: null },  pprLst: [] };
                    for (let s = 1; s < 4; s++) {
                        if (obj[`limit${s}`]) { binObj.totalSum[`limit${s}`] += obj[`limit${s}`]; }
                    }
                    binObj.prgLst.push(obj);
                }
                binObj.prgLst.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                totalArr.push(binObj);
                sortBinFl = true;
            }
        }
        if (sortBinFl) { totalArr.sort((a: any, b: any) => (a.bin > b.bin) ? 1 : -1); }
    }

    private createPprArr(prgArr: any[]) {
        if (!prgArr.length) { return; }
        const pprArr: any[] = [];
        prgArr[prgArr.length - 1].pprLst.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
        for (let i = 0; i < prgArr[prgArr.length - 1].pprLst.length; i++) {
            const elPpr = prgArr[prgArr.length - 1].pprLst[i];
            if (pprArr.length === 0 || pprArr[pprArr.length -1].ppr !== elPpr.ppr) {
                this.createSpfArr(pprArr);
                if (pprArr.length) { this.calcTotal(prgArr[prgArr.length - 1].totalSum, pprArr[pprArr.length - 1]); }
                const objPpr = { ppr: elPpr.ppr, name_ru: elPpr.ppr_name_ru, collapse: this.collapseAll,  totalSum: { value1: 0, value2: 0, value3: 0, proc2: null, proc3: null }, spfLst: [elPpr] }
                pprArr.push(objPpr);
            } else {
                pprArr[pprArr.length - 1].spfLst.push(elPpr);
            }
            if (i === prgArr[prgArr.length - 1].pprLst.length - 1) { 
                this.createSpfArr(pprArr);
                this.calcTotal(prgArr[prgArr.length - 1].totalSum, pprArr[pprArr.length - 1]);
                this.calcProc(prgArr[prgArr.length - 1].totalSum);
            }
        }
        prgArr[prgArr.length - 1].pprLst = pprArr;
    }

    private createSpfArr(pprArr: any[]) {
       if (!pprArr.length || !pprArr[pprArr.length - 1].spfLst.length) { return; }
        pprArr[pprArr.length - 1].spfLst.sort((a: any, b: any) => (a.spf > b.spf) ? 1 : -1);
        const spfArr: any[] = [];
        for (let i = 0; i < pprArr[pprArr.length - 1].spfLst.length; i++) {
            const elSpf = pprArr[pprArr.length - 1].spfLst[i];
            if (spfArr.length === 0 || spfArr[spfArr.length - 1].spf !== elSpf.spf) {
                this.createFormArr(spfArr);
                if (spfArr.length) { this.calcTotal(pprArr[pprArr.length - 1].totalSum, spfArr[spfArr.length - 1]); }
                const spfObj = { spf: elSpf.spf, name_ru: elSpf.spf_name_ru, collapse: this.collapseAll,  totalSum: { value1: 0, value2: 0, value3: 0, proc2: null, proc3: null }, formLst: [elSpf] };
                spfArr.push(spfObj);
            } else {
                spfArr[spfArr.length - 1].formLst.push(elSpf);
            }
            if (i === pprArr[pprArr.length - 1].spfLst.length - 1) { 
                this.createFormArr(spfArr);
                this.calcTotal(pprArr[pprArr.length - 1].totalSum, spfArr[spfArr.length - 1]);
                this.calcProc(pprArr[pprArr.length - 1].totalSum);
            }
        }
        pprArr[pprArr.length - 1].spfLst = spfArr;
    }

    private createFormArr(spfArr: any[]) {
       if (!spfArr.length || !spfArr[spfArr.length - 1].formLst.length) { return; }
        spfArr[spfArr.length - 1].formLst.sort((a: any, b: any) => (a.form > b.form) ? 1 : -1);
        const formArr: any[] = [];
        for (let i = 0; i < spfArr[spfArr.length - 1].formLst.length; i++) {
            const elForm  = spfArr[spfArr.length - 1].formLst[i];
            if (formArr.length === 0 || formArr[formArr.length - 1].form !== elForm.form) {
                if (formArr.length) { this.calcTotal(spfArr[spfArr.length - 1].totalSum, formArr[formArr.length - 1]); }
                const formObj = { value1: null, value2: null, value3: null, proc2: null, proc3: null, form: elForm.form, urlLink1: null, urlLink2: null, urlLink3: null };
                this.setValue(formObj, elForm);
                formArr.push(formObj);
            } else {
                this.setValue(formArr[formArr.length - 1], elForm);
            }
            this.calcProc(formArr[formArr.length - 1]);
            if (i === spfArr[spfArr.length - 1].formLst.length-1) { 
                this.calcTotal(spfArr[spfArr.length - 1].totalSum, formArr[formArr.length - 1]);
                this.calcProc(spfArr[spfArr.length - 1].totalSum);
            }
        }
        spfArr[spfArr.length - 1].formLst = formArr;
    }

    private calcTotal(savedObj: any, childObj: any) {
        for (let i = 1; i < 4; i++) {
            if (childObj.totalSum) {
                if (childObj.totalSum[`value${i}`]) { savedObj[`value${i}`] += childObj.totalSum[`value${i}`]; }
                if (childObj.totalSum[`limit${i}`]) { savedObj[`limit${i}`] += childObj.totalSum[`limit${i}`]; }
            }
            if (childObj[`value${i}`]) { savedObj[`value${i}`] += childObj[`value${i}`]; }
            if (childObj[`limit${i}`]) { savedObj[`limit${i}`] += childObj[`limit${i}`]; }
        }
    }

    private setValue(formObj: any, el: any) {
        for (let i = 1; i < 4; i++) {
            if (el[`value${i}`]) {
                formObj[`value${i}`] = el[`value${i}`];
                formObj[`source_link${i}`] = el[`source_link${i}`];
                if (el[`source_link${i}`]) {
                    formObj[`urlLink${i}`] = `#/${el[`source_link${i}`]}`;
                } else {
                    const href = encodeURI(`#/form${el.form}/${this.curYear}/${el.year}/${el.data_type}/${el.gr}/${el.abp}/${el.prg}/${el.ppr}/false/${el.variant}/${el.spf}/${el.bin}/gkkp/true/end`)
                    formObj[`urlLink${i}`] = href;
                }
                return;
            }
        }
    }


    private getValue(obj: any) {
        obj[`value${(obj.year - this.curYear + 1)}`] = obj.value;
        obj[`source_link${(obj.year - this.curYear + 1)}`] = obj.value_source_link;
        delete obj.value_source_link;
        delete obj.value;
    }
    // --------------------------------------------------


    private async loadTotalGkkp(params: any) {
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-request-form-total-gkkp/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                result = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-request-form-total-gkkp', `${response.status} ${response.statusText}`);
                this.progress = 0;
                return null;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-request-form-total-gkkp', (error as Error).toString());
            this.progress = 0;
            return null;
        }
        this.progress += 25;
        return result;
    }

    private async loadLimitGkkp(params: any) {
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-sum-limit-gkkp/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                result = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-sum-limit-gkkp', `${response.status} ${response.statusText}`);
                this.progress = 0;
                return null;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-sum-limit-gkkp', (error as Error).toString());
            this.progress = 0;
            return null;
        }
        this.progress += 25;
        return result;
    }

    private clkCollapse(calcIndx: number | null, progIndx: number | null, subIndx: number | null, spfIndx: number | null, collapse?: boolean) {
        if (calcIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.calcData) {
                el.collapse = this.collapseAll;
                for (const progEl of el.prgLst) {
                    if (progEl.collapse!==undefined) { progEl.collapse = this.collapseAll; }
                    for (const subEl of progEl.pprLst) {
                        subEl.collapse = this.collapseAll;
                    }
                }
            }
            return;
        }
        if (progIndx === null) {
            this.calcData[calcIndx].collapse = collapse;
            for (const el of this.calcData[calcIndx].prgLst) {
                if (el.collapse!==undefined) { el.collapse = collapse; }
            }
        } else {
            if (subIndx === null) {
                if (this.calcData[calcIndx].prgLst[progIndx].collapse!==undefined) { this.calcData[calcIndx].prgLst[progIndx].collapse = collapse; }
                for (const el of this.calcData[calcIndx].prgLst[progIndx].pprLst) {
                    el.collapse = collapse;
                }
            } else {
                if (spfIndx === null) {
                    this.calcData[calcIndx].prgLst[progIndx].pprLst[subIndx].collapse = collapse;
                } else {
                    this.calcData[calcIndx].prgLst[progIndx].pprLst[subIndx].spfLst[spfIndx].collapse = collapse;
                }
            }
        }
    }
}
