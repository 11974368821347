








































































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/services/store';
import { calcProgn } from '@/modules/budget-request/components/js/budgetCalcProgn';
import CBudgetAgreementModal, { iAgrBtns } from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue';
import CBudgetAgreementHistModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue';
import CBudgetSumCalcGuLevelModal from '@/modules/budget-request/components/budget-sum-calc/budget-sum-calc-gulevel-modal.vue';
import CBudgetUserSignModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-sign-modal.vue';
import CBudgetUserStartSignModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-start-sign-modal.vue';
import CBudgetUserSignRecalModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-sign-recal-modal.vue';
import CBudgetUserSignLogModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-sign-log-modal.vue';
import CBudgetAgreementSpfLogModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-spf-log-modal.vue';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';
import NCAlayerService from '@/services/ncalayer';
import * as CryptoJS from 'crypto-js';

@Component({
    name: 'c-calc-prog-tab',
    components: {
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
        'c-guelevel-modal': CBudgetSumCalcGuLevelModal,
        'c-sign-modal': CBudgetUserSignModal,
        'c-sign-recal-modal': CBudgetUserSignRecalModal,
        'c-sign-log-modal': CBudgetUserSignLogModal,
        'c-start-sign-modal': CBudgetUserStartSignModal,
        'c-agr-spf-log-modal': CBudgetAgreementSpfLogModal
    }})
export default class CBudgetSumCalcProgTable extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYearProp!: number;

    @Prop({
        required: true,
        default: null
    })
    private curAbpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curDataTypeProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private regionProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curVariantProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private variantEndDateProp!: any | null;

    private curYear: number = 0;
    private curAbp: any = null;
    private curDataType: any = null;
    private curRegion: any = null;
    private curVariant: any = null;

    private variantEndDate: any | null = null;
    private mainGuCode: string | null = null;


    private get region() {
        if (this.regionProp) { return this.regionProp.code; }
        return null;
    }

    private progress = 0;
    private calcData: any[] = [];
    private collapseAll = true;
    private limit: any = { sum: 0, limit: 0 };
    private totalSum: any = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0,  limit0: 0, limit1: 0, limit2: 0 };

    private checkedAll = false;

    private readonly signOperation = 'ecp_sign_abp';
    private signOperEnable = false; // доступность операции подписания
    private userSignEnable = false; // доступность подписания для пользователя
    private signUserLst: any[] = []; // список всех подписантов
    private curUserSigned: any | null = null; // подписан ли текущий пользователь
    private signStatus: {id: number, name: string} | null = null; // стутус подписания ( 0 не подписан - ни одной подписи; 1 частично подписан - подписи есть, но не все; 2- полностью подписан - все подписи имеются.)
    private totalSumWithoutBip: any = { prog1: 0, prog2: 0, prog0: 0 }; // сумма totalSum без БИП для подписания

    // доступность согласования
    private agrEnabled = false;

    private readonly modeCode = 'bp';
    private operationCode: string[] = []; // операции согласования
    private operationSignCode: string[] = []; // операции подписания

    private selectArr: any = [];
    private agrBtnLst: iAgrBtns = { back: [], forward: [] };

    // параметры для открытия уровня ГУ
    private guLvlParam: any | null = null;

    private mounted() {
        this.curAbp = this.curAbpProp;
        this.curYear = this.curYearProp;
        this.curDataType = this.curDataTypeProp;
        this.curRegion = this.regionProp;
        this.curVariant = this.curVariantProp;
        this.variantEndDate = this.variantEndDateProp;
        // await this.loadOperations();
        this.loadData();
        this.setAgrEnabled();

        this.$watch('curVariant', () => {
            this.setAgrEnabled();
        });

        this.$watch('usrId', () => {
            if (!this.usrId) { return; }
            this.loadOperations();
        })
    }


    private async loadOperations() {
        this.operationCode = [];
        this.operationSignCode = [];
        if (this.usrId === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${this.usrId}&modulecode=004.002.002.001`));
            if (result.status === 200) {
                result = await result.json();
            }  else {
                if (result.status !== 404) { makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', `${result.status}  ${result.statusText}`); }
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', (error as Error).toString());
            return;
        }
        if (result.operations) {
            for (const el of result.operations) {
                if (el.startsWith('ecp_')) {
                    this.operationSignCode.push(el);
                } else {
                    this.operationCode.push(el);
                }
            }
        }
        this.$emit('chgAgrAccess', this.operationCode.length > 0);
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private get usrName(): string | null {
        // return 'abab80c6-525b-4151-9499-a1ff21a157d2'; // ----- временнно!!!!!!!!
        if (!store.state.user.preferred_username) { return null; }
        return store.state.user.preferred_username;
    }

    private setAgrEnabled() {
        if (this.curVariant && this.curVariant.attribute && !this.curVariant.status) {
            this.agrEnabled = true;
        } else {
            this.agrEnabled = false;
        }
    }

    private chgEvent(params: any) {
        this.curAbp = params.curAbp;
        this.curYear = params.curYear;
        this.curDataType = params.curDataType;
        this.curRegion = params.regionProp;
        this.curVariant = params.curVariant;
        this.variantEndDate = params.variantEndDate;
        this.loadData();
    }

    private async loadTotalAgreement() {
        if (!this.operationCode.length) { await this.loadOperations(); }
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.region, variant: this.curVariant.variant_uuid, mode_code: this.modeCode, operation_code: this.operationCode, sign_operation_code: this.operationSignCode });
        let result = null;
        try {
            const response: any = await fetch(`/api-py/get-budget-total-agreement-abp/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
                this.signOperEnable = this.checkOperationCode(this.signOperation, result);
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-total-agreement', `${response.status} - ${response.statusText}`);
                this.progress = 0;
                return new Map();
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-total-agreement', (error as Error).toString());
            this.progress = 0;
            return new Map();
        } finally {
            this.chgSignAcces();
        }
        //
        this.progress+=10;
        return this.createTreeMap(result, true, true);
    }

    // проверить есть ли код операций во всех кодах согласования
    private checkOperationCode(operCode: string, agrArr: any[]) {
        if (!agrArr || !agrArr.length) { return false; }
        for (const el of agrArr) {
            let fl = false;
            if (!el.operation_codes) { return false; }
            for (let i = 0; i < el.operation_codes.length; i++) {
                const opEl = el.operation_codes[i];
                if (opEl === operCode) {
                    fl = true;
                    break;
                }
            }
            if (!fl) { return false; }
        }
        return true;
    }

    private zeroDataDelete(result: any[], dataName: string) {
        for (let i = 0; i < result.length; i++) {
            const el = result[i];
            if (dataName === 'expens-prog') {
                if (!el.fact_ras && !el.kass_ras && !el.utoch_plan) {
                    result.splice(i, 1);
                    i--;
                }
            } else if (dataName === 'total-prog') {
                if (!el.limit0 && !el.limit1 && !el.limit2 && !el.value) {
                    result.splice(i, 1);
                    i--;
                }
            }
        }
    }

    // ----- отображение согласования по спецификам из режима Формирование бюджета
    private async loadSpfAgrMaxStatus() {
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, region: this.region, variant: this.curVariant.variant_uuid });
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-agreement-spf-hist-max-status/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-agreement-spf-hist-max-status', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-agreement-spf-hist-max-status', (error as Error).toString());
            return [];
        }
        return result;
    }

    private getSpfAgrMaxStatusMap(resultLst: any[]) {
        const resMap = new Map();

        for (const el of resultLst) {
            if (!resMap.has(el.prg)) {
                resMap.set(el.prg, new Map());
            }
            const prgMap = resMap.get(el.prg);

            if (!prgMap.has(el.ppr)) {
                prgMap.set(el.ppr, new Map());
            }
            const pprMap = prgMap.get(el.ppr);

            if (!pprMap.has(el.spf)) {
                pprMap.set(el.spf, el);
            } else {
                const existingEl = pprMap.get(el.spf);
                if (el.update_date > existingEl.update_date) {
                    pprMap.set(el.spf, el);
                }
            }
        }

        return resMap;
        /*const resMap = new Map();
        for (const el of resultLst) {
            let tmp = resMap.get(el.prg);
            if (tmp) {
                tmp.push(el);
            } else {
                tmp = [el];
            }
            resMap.set(el.prg, tmp);
        }
        for (const [key, value] of resMap) {
            const subProg = new Map();
            for (const el of value) {
                let tmp = subProg.get(el.ppr);
                if (tmp) {
                    tmp.push(el);
                } else {
                    tmp = [el];
                }
                subProg.set(el.ppr, tmp);
            }
            for (const [keySub, valSub] of subProg) {
                const spfMap = new Map();
                // --------------------
                for (const el of valSub) {
                    spfMap.set(el.spf, el);
                // -----------------
                }
                subProg.set(keySub, spfMap);
            }
            resMap.set(key, subProg);
        }
        return resMap;*/
    }

    /**
     * проставить в calcData статусы согласования Spf
     * @param spfAgrStatMap
     */
    private setSpfAgrStat(spfAgrStatMap: Map<any, any> | null) {
        if (!spfAgrStatMap) { return; }
        for (const elPrg of this.calcData) {
            if (spfAgrStatMap.has(elPrg.prg)) {
                const prgMap = spfAgrStatMap.get(elPrg.prg);
                // ---
                if (elPrg.pprNullArr) {
                    if (prgMap.has(null)) {
                        const pprMap = prgMap.get(null);
                        for (const elSpf of elPrg.pprNullArr) {
                            if (pprMap.has(elSpf.consExp.spf)) {
                                this.$set(elSpf, 'spfAgrStat', pprMap.get(elSpf.consExp.spf).status);
                            }
                        }
                    }
                }
                //---
                for (const elPpr of elPrg.vals) {
                    if (prgMap.has(elPpr.ppr)) {
                        const pprMap = prgMap.get(elPpr.ppr);
                        for (const elSpf of elPpr.vals) {
                            if (pprMap.has(elSpf.consExp.spf)) {
                                this.$set(elSpf, 'spfAgrStat', pprMap.get(elSpf.consExp.spf).status);
                            }
                        }
                    }
                }
                //---
            }
        }
    }


    // -----

    // загрузка данных
    private async loadData() {
        this.calcData = [];
        this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 };
        this.signStatus = null;
        this.signUserLst = [];
        this.curUserSigned = null;
        this.userSignEnable = false;
        this.signOperEnable = false;
        if (!this.curAbp || !this.curDataType || !this.curYear || !this.curVariant) { return; }
        this.chgSignAcces();
        this.collapseAll = true;
        this.calcData = [];
        this.progress = 30;
        // this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0 };

        // this.calcData = [];
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.region, variant: this.curVariant.variant_uuid, date_b: this.curVariant.date_start, date_e: this.variantEndDate, locale: this.$i18n.locale });
        this.loadSign();
        const result = await Promise.all([
            this.loadConsExpProg(params),
            this.loadTotalProg(params),
            this.loadTotalAgreement(),
            this.loadSpfAgrMaxStatus()
        ])
        if (result[0] === null || result[1] === null) { return; }
        let spfAgrStatMap: any = null;
        if (result[3]) { spfAgrStatMap = this.getSpfAgrMaxStatusMap(result[3]); }
        this.getTotalSumWithoutBip(result[1].total);

        const mapConsol = this.createTreeMap(result[0], true);
        // ----------------загрузка лимитов--------------------
        /* let limitData: any[] = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-sum-limit/${encodeURI(params)}`);
            limitData = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка get-req-total', (error as Error).toString());
            this.progress = 0;
            return;
        } */
        // ---------------------------------------

        const newMap = this.createTreeMap(result[1].total, false);
        const limMap = this.createLimitMap(result[1].limit);
        const agreementMap: any = result[2];
        this.getCalcData(newMap, agreementMap, limMap, mapConsol, spfAgrStatMap);
    }

    /**
     * расчет суммы без бипов для подписания
     * @param dataLst
     */
     private getTotalSumWithoutBip(dataLst: any[]) {
        this.totalSumWithoutBip = { prog1: 0, prog2: 0, prog0: 0 };
        for (const el of dataLst) {
            if (el.form !== null && el.value) {
                const indx = el.year - this.curYear;
                this.totalSumWithoutBip[`prog${indx}`] += el.value;
            }
        }
    }

    /**
     * загрузка totalProg
     */
    private async loadTotalProg(params: any) {
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-req-total-prog/${encodeURI(params)}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-req-total', (error as Error).toString());
            this.progress = 0;
            return;
        }
        this.zeroDataDelete(result, 'total-prog');
        this.progress+=25;
        return result;
    }

    /**
     * загрузка budget-consolidate-expens-prog-gu
     * @param params
     */
    private async loadConsExpProg(params: any) {
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-consolidate-expens-prog/${encodeURI(params)}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens-prog', (error as Error).toString());
            this.progress = 0;
            return null;
        }
        this.zeroDataDelete(result, 'expens-prog');
        this.progress+=25;
        return result;
    }

    /**
     * сформировать calcData
     * @param newMap
     * @param agreementMap
     * @param limMap
     * @param mapConsol
     */
    private getCalcData(newMap: Map<any, any>, agreementMap: Map<any, any>, limMap: Map<any, any>, mapConsol: Map<any, any>, spfAgrStatMap: Map<any, any> | null) {
        const tmpTotal = [{ totalSum: { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 } }];
        const calcData: any[] = [];
        for (const [keyProg, valProg] of newMap) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            calcData.push({ name: null, prg: keyProg, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 } });
            const subArr: any[] = [];
            const pprNullArr: any[] = [];
            let flSubAgr = false; // true - есть записи с согласованием на уровне подпрограммы
            for (const [keySub, valSub] of valProg) {
                // eslint-disable-next-line @typescript-eslint/camelcase
                    const tmp1: any = { name: null, ppr: keySub, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, agrObj: null, limit0: 0, limit1: 0, limit2: 0 } };
                    // ---limit
                    if (keySub!== null) { this.setLimitFromMap(limMap, tmp1, keyProg, keySub); }
                    // ---
                    if (agreementMap.get(keyProg) && agreementMap.get(keyProg).get(keySub)) {
                        const tmpAgr = agreementMap.get(keyProg).get(keySub);
                        const tmpAgr2 = { id: tmpAgr.id, status: tmpAgr.status.code, updateDate: tmpAgr.update_date, userId: tmpAgr.user_id, commentTxt: tmpAgr.comment_txt, statusName: tmpAgr.status.name_ru, status_lang: { name_ru: tmpAgr.status.name_ru, name_kk: tmpAgr.status.name_kk, name_en: tmpAgr.status.name_en, availableStep: tmpAgr.availableStep } };
                        if (tmpAgr.availableStep) { flSubAgr = true; }
                        if (keySub !== null) {
                            tmp1.agrObj = tmpAgr2;
                            if (tmpAgr.availableStep) { tmp1.checked = this.checkedAll; }
                        } else {
                            calcData[calcData.length -1].agrObj = tmpAgr2;
                            if (tmpAgr.availableStep) { calcData[calcData.length -1].checked = this.checkedAll; }
                        }
                    }

                if (keySub !== null) { subArr.push(tmp1); }
                for (const [key, value] of valSub) {
                    const obj: any = {};
                    if (mapConsol.get(keyProg) && mapConsol.get(keyProg).get(keySub) && mapConsol.get(keyProg).get(keySub).get(key)) {
                        const tmpCons = mapConsol.get(keyProg).get(keySub).get(key);
                        obj.consExp = { 'cur_year': this.curYear, 'data_type': this.curDataType, abp: this.curAbp, prg: keyProg, ppr: keySub, spf: key, 'kass_ras': tmpCons.kass_ras, 'fact_ras': tmpCons.fact_ras, 'utoch_plan': tmpCons.utoch_plan, prog: tmpCons.prog, subProg: tmpCons.subProg, spfName: tmpCons.spfName };
                        mapConsol.get(keyProg).get(keySub).delete(key);
                    } else {
                        const tmp = this.getSpfObj(value);
                        obj.consExp = { 'cur_year': this.curYear, 'data_type': this.curDataType, abp: this.curAbp, prg: keyProg, ppr: keySub, spf: key, 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog: tmp.prog, subProg: tmp.subProg, spfName: tmp.spfName };
                    }
                    for (const colName of Object.keys(value)) {
                        if (colName !== 'trash') {
                            const v = calcProgn(value[colName], key);
                            obj[colName] = v;
                        }
                    }
                    if (keySub !== null) {
                        subArr[subArr.length - 1].vals.push(obj);
                        this.calcSum(subArr, obj);
                        if (subArr[subArr.length - 1].name === null) { subArr[subArr.length - 1].name = obj.consExp.subProg; }
                    } else {
                        pprNullArr.push(obj);
                        this.calcSum(calcData, obj);
                    }
                    if (calcData[calcData.length - 1].name === null) { calcData[calcData.length - 1].name = obj.consExp.prog; }
                }
                if (keySub !== null) {
                    this.calcSum(calcData, subArr[subArr.length - 1].totalSum);
                }
            }
            if (flSubAgr) {
                calcData[calcData.length - 1].checked = this.checkedAll;
            }
            this.setLimitFromMap(limMap, calcData[calcData.length - 1], keyProg, null);
            calcData[calcData.length - 1].vals = subArr;
            if (pprNullArr.length) {
                calcData[calcData.length - 1].pprNullArr = pprNullArr;
            }

            this.calcSum(tmpTotal, calcData[calcData.length - 1].totalSum);
        }

        // --- добавить оставшиейся с mapConsol
        for (const [keyProg, valProg] of mapConsol) {
            for (const [keySub, valSub] of valProg) {
                for (const [keySpf, valSpf] of valSub) {
                    const obj = { limit0: null, limit1: null, limit2: null, prog0: null, prog1: null, prog2: null, consExp: valSpf };
                    // ----
                    let flProg = false;
                    for (let i = 0; i < calcData.length; i++) {
                        const calcProg = calcData[i];
                        if (calcProg.prg === keyProg) {
                            flProg = true;
                            let flSub = false;
                            for (let j = 0; j < calcProg.vals.length; j++) {
                                const calcSub = calcProg.vals[j];
                                if (calcSub.ppr === keySub) {
                                    flSub = true;
                                    calcSub.vals.push(JSON.parse(JSON.stringify(obj)));
                                    calcSub.vals.sort((a: any, b: any) => (a.consExp.spf > b.consExp.spf) ? 1 : -1);
                                    this.twoObjectsTotalSum(calcSub, valSpf);
                                    this.twoObjectsTotalSum(calcProg, valSpf);
                                    this.twoObjectsTotalSum(tmpTotal[0], valSpf);
                                    break;
                                }
                            }
                            if (!flSub) {
                                const totalSum = { kass_ras: valSpf.kass_ras, fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 };
                                if (valSpf.ppr!==null) {
                                    const subObj = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: totalSum };
                                    calcProg.vals.push(JSON.parse(JSON.stringify(subObj)));
                                } else {
                                    const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf };
                                    if (!calcProg.pprNullArr) { calcProg.pprNullArr = []; }
                                    calcProg.pprNullArr.push(JSON.parse(JSON.stringify(pprNullObj)));
                                }

                                this.twoObjectsTotalSum(calcProg, valSpf);
                                this.twoObjectsTotalSum(tmpTotal[0], valSpf);
                                calcProg.vals.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
                            }
                            break;
                        }
                    }
                    if (!flProg) {
                        const totalSum = { kass_ras: valSpf.kass_ras, fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 };
                        let progObj = null;
                        if (valSpf.ppr!==null) {
                            const subObj = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: totalSum };
                            progObj = { collapse: this.collapseAll, name: valSpf.prog, prg: valSpf.prg, vals: [subObj], totalSum: Object.assign({}, totalSum) };
                        } else {
                            const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf }
                            progObj = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: totalSum, vals: [], pprNullArr: [pprNullObj] };
                        }
                        calcData.push(JSON.parse(JSON.stringify(progObj)));
                        this.twoObjectsTotalSum(tmpTotal[0], valSpf);
                        calcData.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                    }

                }
            }
        }
        // ----------добавить оставшиейся лимиты из limMap------------
        for (const [keyPrg, valPrg] of limMap) {
            for (const [keySub, valSub] of valPrg) {
                let flPrg = false;
                for (let i = 0; i<calcData.length; i++) {
                    const el = calcData[i];
                    if (el.prg === keyPrg) {
                        if (keySub === null) {
                            this.setLimitFromMap(limMap, el, keyPrg, null);
                            this.twoObjectsLimitTotalSum(tmpTotal[0], el.totalSum);
                        } else {
                            let flSub = false;
                            for (let j = 0; j < el.vals.length; j++) {
                                const elSub = el.vals[j];
                                if (elSub.ppr === keySub) {
                                    flSub = true;
                                    this.setLimitFromMap(limMap, elSub, keyPrg, keySub);
                                    this.twoObjectsLimitTotalSum(el, elSub.totalSum);
                                    this.twoObjectsLimitTotalSum(tmpTotal[0], elSub.totalSum);
                                    break;
                                }
                            }
                            if (!flSub) {
                                const totalSum = { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 };
                                const subObj = { agrObj: null, collapse: null, ppr: keySub, name: valSub.subProg, vals: [], totalSum: totalSum };
                                this.setLimitFromMap(limMap, subObj, keyPrg, keySub);
                                el.collapse = this.collapseAll;
                                el.vals.push(subObj);
                                this.twoObjectsLimitTotalSum(el, totalSum);
                                this.twoObjectsLimitTotalSum(tmpTotal[0], totalSum);
                                el.vals.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
                            }
                        }
                        flPrg = true;
                        break;
                    }
                }
                if (!flPrg) {
                    let progObj = null;
                    const totalSum = { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, limit0: (valSub.limit0 ? valSub.limit0 : 0), limit1: (valSub.limit1 ? valSub.limit1 : 0), limit2: (valSub.limit2 ? valSub.limit2 : 0) };
                    if (keySub !== null) {
                        const subObj = { agrObj: null, collapse: null, ppr: keySub, name: valSub.subProg, vals: [], totalSum: totalSum };
                        progObj = { collapse: this.collapseAll, name: valSub.prog, prg: valSub.prg, vals: [subObj], totalSum: Object.assign({}, totalSum) };
                    } else {
                        progObj = { collapse: null, prg: valSub.prg, name: valSub.prog, totalSum: totalSum, vals: [], pprNullArr: [] };
                    }

                    calcData.push(progObj);
                    this.twoObjectsLimitTotalSum(tmpTotal[0], totalSum);
                    calcData.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                }
            }
        }
        // ----------------------
        this.calcData = calcData;
        this.totalSum = tmpTotal[0].totalSum;
        this.progress = 100;
        this.loadLimit();
        this.setSpfAgrStat(spfAgrStatMap);
    }

    // --- проставить лимиты с limMap в obj
    private setLimitFromMap(limMap: Map<number, any>, obj: any, keyProg: number, keySub: number | null) {
        if (limMap.get(keyProg)) {
            const limObj = limMap.get(keyProg).get(keySub);
            if (limObj) {
                obj.totalSum.limit0 = limObj.limit0 ? limObj.limit0 : 0;
                obj.totalSum.limit1 = limObj.limit1 ? limObj.limit1 : 0;
                obj.totalSum.limit2 = limObj.limit2 ? limObj.limit2 : 0;
                limMap.get(keyProg).delete(keySub);
                if (limMap.get(keyProg).size === 0) { limMap.delete(keyProg); }
            }
         }
    }

    private twoObjectsLimitTotalSum(res: any, obj: any) {
        res.totalSum.limit0 += obj.limit0;
        res.totalSum.limit1 += obj.limit1;
        res.totalSum.limit2 += obj.limit2;
    }

    private twoObjectsTotalSum(res: any, obj: any) {
        res.totalSum.fact_ras += obj.fact_ras;
        res.totalSum.utoch_plan += obj.utoch_plan;
        res.totalSum.kass_ras += obj.kass_ras;
    }

    // ---------- лимит по годам в таблице
    private addLimitData(limitData: any[], obj: any) { // добавить в объект
        for (let i = 0; i < limitData.length; i++) {
            const el = limitData[i];
            if (el.prg === obj.consExp.prg && el.ppr === obj.consExp.ppr && el.spf === obj.consExp.spf) {
                obj.limit0 = el.forecast_for_year;
                obj.limit1 = el.forecast_for_two_years;
                obj.limit2 = el.forecast_for_three_years;
                limitData.splice(i, 1);
            }
        }
    }

    /* private limitToCalcData(limitData: any) { // оставшийся лимит новые строки
        limitData.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
    } */
    // -------------

    private async loadLimit() { // ------ лимит нижняя строка
        let result = null;
        let sum = 0;
        for (const el of this.calcData) {
            sum += el.totalSum.prog0;
        }
        this.limit = { sum: sum.toFixed(2), limit: 0 };
        const params = { year: parseInt(this.curYear.toString()) + 1, abp: this.curAbp.abp, region: this.region };
        try {
            const response: any = await fetch(`/api-py/get-sum-calc-total-limit/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.limit) {
                    this.limit.limit = result.limit.toFixed(2);
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-sum-calc-total-limit', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-sum-calc-total-limit', (error as Error).toString());
            this.progress = 0;
        }
    }
    // -----------------------
    // map для лимитов
    private createLimitMap(limitArr: any[]) {
        const resMap = new Map();
        for (const el of limitArr) {
            let tmp = resMap.get(el.prg);
            if (tmp) {
                tmp.push(el);
            } else {
                tmp = [el];
            }
            resMap.set(el.prg, tmp);
        }
       for (const [key, value] of resMap) {
            const subProg = new Map();
            const curYear = parseInt(this.curYear.toString());
            for (const el of value) {
                let subObj = subProg.get(el.ppr);
                if (!subObj) {
                    subObj = { prg: el.prg,  ppr: el.ppr, prog: el.prog, subProg: el.subProg, limit0: (el.year === curYear ? el.value : 0), limit1: (el.year === curYear + 1 ? el.value : 0), limit2: (el.year === curYear + 2 ? el.value : 0) }
                } else {
                    subObj[`limit${el.year - curYear}`] = el.value;
                }
                subProg.set(el.ppr, subObj);
            }
            resMap.set(key, subProg);
        }
        return resMap;
    }


    // ---notSpf - без специфики
    private createTreeMap(arr: any[], consolidate: boolean, notSpf?: boolean) {
        const resMap = new Map();
        for (const el of arr) {
            let tmp = resMap.get(el.prg);
            if (tmp) {
                tmp.push(el);
            } else {
                tmp = [el];
            }
            resMap.set(el.prg, tmp);
        }
        for (const [key, value] of resMap) {
            const subProg = new Map();
            for (const el of value) {
                let tmp = subProg.get(el.ppr);
                if (tmp) {
                    tmp.push(el);
                } else {
                    tmp = [el];
                }
                subProg.set(el.ppr, tmp);
            }
            for (const [keySub, valSub] of subProg) {
                const spfMap = new Map();
                if (!notSpf) {
                    if (consolidate) {
                        for (const el of valSub) {
                            spfMap.set(el.spf, el);
                        }
                    } else {
                        // --------------------
                        for (const el of valSub) {
                            const fieldName = this.getSumFieldName(el.year);
                            const findObj = spfMap.get(el.spf);
                            if (findObj === undefined) {
                                const newObj: any = { prog0: new Map(), prog1: new Map(), prog2: new Map(), trash: new Map() };
                                newObj[fieldName].set(el.form, el);
                                spfMap.set(el.spf, newObj);
                            } else if (findObj[fieldName]) {
                                if (el.form === null && findObj[fieldName].get(null)) {
                                    el.value += findObj[fieldName].get(null).value;
                                }
                                findObj[fieldName].set(el.form, el);
                                spfMap.set(el.spf, findObj);
                            }
                        }

                        // -----------------
                    }
                    subProg.set(keySub, spfMap);
                } else {
                    if (valSub.length > 0) { subProg.set(keySub, valSub[0]); }
                }
            }
            resMap.set(key, subProg);
        }
        return resMap;
    }

    // получение имени поля прогноза (trash - не подходящий год)
    private getSumFieldName(year: number): string {
        if (year - this.curYear < 0 || year - this.curYear > 3) { return 'trash'; }
        return `prog${(year - this.curYear)}`;
    }

    private clkCollapse(calcIndx: number | null, subIndx: number | null, collapse?: boolean) {
        if (calcIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.calcData) {
                el.collapse = this.collapseAll;
                for (const subEl of el.vals) {
                    if (subEl.collapse !== null) { subEl.collapse = this.collapseAll; }
                }
            }
            return;
        }
        if (subIndx === null) {
            this.calcData[calcIndx].collapse = collapse;
            for (const el of this.calcData[calcIndx].vals) {
                if (el.collapse!==null) { el.collapse = collapse; }
            }
        } else {
            if (this.calcData[calcIndx].vals[subIndx].collapse !==null) { this.calcData[calcIndx].vals[subIndx].collapse = collapse; }
        }
    }

    private clkCheck(calcIndx: number | null, subIndx: number | null){
        if (calcIndx === null) {
            for (const el of this.calcData) {
                if (el.checked!==undefined){ el.checked = this.checkedAll; }
                for (const subEl of el.vals) {
                    if (subEl.checked!=undefined) { subEl.checked = this.checkedAll; }
                }
            }
            return;
        }
        let checked = this.calcData[calcIndx].checked;
        if (subIndx === null) {
            for (const el of this.calcData[calcIndx].vals) {
                if (el.checked!==undefined) { el.checked = checked; }
            }
        } else {
            checked = this.calcData[calcIndx].vals[subIndx].checked;
            if (checked && !this.calcData[calcIndx].checked) {
                for (const el of  this.calcData[calcIndx].vals) {
                    if (!el.checked) { return; }
                }
                if (this.calcData[calcIndx].checked!==undefined) { this.calcData[calcIndx].checked = true; }
            } else if (!checked && this.calcData[calcIndx].checked) {
                if (this.calcData[calcIndx].checked!==undefined) { this.calcData[calcIndx].checked = false; }
            }
        }

        for (const el of this.calcData) {
            if (el.checked === false) {
                this.checkedAll = false;
                return;
            }
        }
        this.checkedAll = true;
    }

    private getSpfObj(spfVal: any) {
        for (const colName of Object.keys(spfVal)) {
            if (spfVal[colName].size > 0) {
                return spfVal[colName].entries().next().value[1];
            }
        }
        return {};
    }

    private calcSum(sumArr: any[], obj: any) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        let consObj = obj;
        if (obj.consExp) { consObj = obj.consExp; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.kass_ras) { sumArr[sumArr.length - 1].totalSum.kass_ras += consObj.kass_ras; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.fact_ras) { sumArr[sumArr.length - 1].totalSum.fact_ras += consObj.fact_ras; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.utoch_plan) { sumArr[sumArr.length - 1].totalSum.utoch_plan += consObj.utoch_plan; }
        if (obj.prog1) { sumArr[sumArr.length - 1].totalSum.prog1 += obj.prog1; }
        if (obj.prog2) { sumArr[sumArr.length - 1].totalSum.prog2 += obj.prog2; }
        if (obj.prog0) { sumArr[sumArr.length - 1].totalSum.prog0 += obj.prog0; }
        if (obj.limit0) { sumArr[sumArr.length - 1].totalSum.limit0 += obj.limit0; }
        if (obj.limit1) { sumArr[sumArr.length - 1].totalSum.limit1 += obj.limit1; }
        if (obj.limit2) { sumArr[sumArr.length - 1].totalSum.limit2 += obj.limit2; }
    }

    // кнопка действия
    private async agreementEvent() {
        const selectArr: any = [];
        this.selectArr = [];
        this.agrBtnLst = { back: [], forward: [] };
        let firstStatus: number | null = null;
        for (const prog of this.calcData) {
            if (prog.pprNullArr && prog.pprNullArr.length && prog.checked && prog.agrObj) { // без подпрограммы
                if (firstStatus === null) { firstStatus = prog.agrObj.status; }
                if (firstStatus !== prog.agrObj.status) {
                    makeToast(this, 'danger', 'Согласование', 'Выберите подпрограммы с одинаковым статусом!');
                    return;
                }
                if (!this.chkAgrLimit(prog.totalSum)) { return; }
                selectArr.push({ consExp: prog.pprNullArr[0].consExp, agrObj: prog.agrObj });
            }
            for (const sub of prog.vals) {
                if (sub.checked && sub.vals.length && sub.agrObj) {
                    if (firstStatus === null) { firstStatus = sub.agrObj.status; }
                    if (firstStatus !== sub.agrObj.status) {
                        makeToast(this, 'danger', 'Согласование', 'Выберите подпрограммы с одинаковым статусом!');
                        return;
                    }
                    const fl = await this.chkAgrLimit(sub.totalSum);
                    if (!fl) { return; }
                    for (let i = 0; i < sub.vals.length; i++) {
                        if (sub.vals[i].consExp.abp) {
                            selectArr.push({ consExp: sub.vals[i].consExp, agrObj: sub.agrObj });
                            break;
                        }
                    }
                }
            }
        }
        if (selectArr.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Не выбрано ни одной программы/подпрограммы!');
            return;
        }
        this.selectArr = selectArr;

        const params = { modeCode: this.modeCode, operationCode: this.operationCode, agrCode: firstStatus };

        let result: any = null;
        try {
            const response: any = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', (error as Error).toString());
            this.progress = 0;
            return;
        }
        if (result.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
            return;
        }
        for (const el of result) {
            let name = el[`name_${this.$i18n.locale}`];
            if (!name) { name = el.name_ru; }
            el.name = name;
            if (el.stepType === 1) {
                this.agrBtnLst.back.push(el);
            } else {
                this.agrBtnLst.forward.push(el);
            }
        }
         // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refAgrModal.showEvent();
    }

    // проверить Прогноз не должен быть меньше лимита
    private async chkAgrLimit(totalSum: any) {
        for (let i=0; i<3; i++){
            if (totalSum[`prog${i}`]< totalSum[`limit${i}`]) {
                /* let errTxt = 'Невозможно отправить на согласование, так как прогнозные данные меньше установленного лимита. Проверьте данные';
                if (this.$i18n.locale == 'kk') { errTxt = 'Бекітуге жіберу мүмкін емес, себебі болжам деректері белгіленген шектен аз. Деректерді тексеріңіз'; }
                makeToast(this, 'danger', 'Согласование', errTxt);
                return false; */

                const txt = 'Прогнозные значения меньше Лимитов на уровне бюджетной подпрограммы. Проверьте, по всем ли гос.учреждениям (ГУ) у вас заполнены данные. Вы действительно хотите отправить на рассмотрение в Управление (Отдел) экономики?';
                let fl = false;
                await this.$bvModal.msgBoxConfirm (
                    txt,
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Да',
                        cancelTitle: 'Нет',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        noCloseOnBackdrop: true
                    })
                    .then(value => {
                        fl = value;
                    })
                    .catch(error => {
                        fl = false;
                    });
                    return fl;
            }
        }
        return true;
    }

    private async agrClk(data: any) {
        data.userId = store.state.user.sub;
        data.variant = this.curVariant.variant_uuid;
        data.region = this.region;
        data.data_type = this.curDataType.code;

        const saveArr = []
        for (const el of this.selectArr) {
            saveArr.push(Object.assign({ id: el.agrObj.id, consExp: el.consExp }, data));
        }
        const saveObj = {
            signOperation: this.signOperation,
            modeCode: this.modeCode,
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            userId: this.usrId,
            items: saveArr,
        };
        let result: any = await fetch('/api-py/set_brft_agreement_step_abp',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            for (const res of result) {
                if (res.result === 'error') {
                    makeToast(this, 'danger', 'Сохранение status', res.errTxt);
                    console.error('Ошибка set_brft_agreement_step_abp', res);
                    return;
                }
            }
            this.loadData();
        } else {
            makeToast(this, 'danger', 'Сохранение status', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

    private commentClk(progIndx: number, subIndx: number | null) {
        let agrObj: any = null;
        if (subIndx === null) {
            agrObj = this.calcData[progIndx].agrObj;
        } else {
            agrObj = this.calcData[progIndx].vals[subIndx].agrObj;
        }

        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refHistModal.showEvent(agrObj);
    }

    // показать ГУ
    private clkShowGu(obj: any, prog: number) {
        const param = {
                        year: this.curYear + prog,
                        variant: this.curVariant.variant_uuid,
                        abp: obj.consExp.abp.abp,
                        curYear: obj.consExp.cur_year,
                        dataType: obj.consExp.data_type.code,
                        prg: obj.consExp.prg,
                        ppr: obj.consExp.ppr,
                        spf: obj.consExp.spf
                    };
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refGuLvlModal.showEvent(param);
        return false;
    }

    /**
     * загрузка головного ГУ
    */
    private async loadMainGu() {
        this.mainGuCode = null;
        let result: any = null;
        const params = { region: this.region, abp: this.curAbp.abp, dateB: this.curVariant.date_start, dateE: this.variantEndDate }
        try {
            const response: any = await fetch(`/api-py/get-gu-main-by-region-abp-period/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                result = await response.json();
                this.mainGuCode = result.gu_main;
            } else {
                makeToast(this, 'danger', `Ошибка ${response.status} get-gu-main-by-region-abp-period`, response.statusText);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-gu-main-by-region-abp-period', (error as Error).toString());
        }
    }

    /**
     * загрузка данных пользователя по usrId
     */
     private async loadUserName(usrId: string) {
        let result = null;
        try {
            const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(usrId)}`);
            if (response.status === 200) {
                result = await response.json();
            } else {
                makeToast(this, 'danger', 'Подписание', `Ошибка ${response.status}`);
                console.error(response);
                return null;
            }
        }  catch (error) {
            makeToast(this, 'danger', 'loadUserName', (error as Error).toString());
            result = null;
        } finally {
            return result;
        }
    }

    private spfAgrLogClk(spfObj: any) {
        const params = {
            abp: this.curAbp.abp,
            variant: this.curVariant.variant_uuid,
            region: this.curRegion.code,
            curYear: this.curYear,
            dataType: this.curDataType.code,
            prg: spfObj.consExp.prg,
            ppr: spfObj.consExp.ppr,
            spf: spfObj.consExp.spf
        }
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refAgrSpfLogModal.showEvent(params);
    }

    // ---------------------------------------------------------- Подписи ------------------------------------------------------
    /**
     * загрузка всех подписей и подписантов
     */
    private async loadSign() {
        const result = await Promise.all([
            this.loadUserSignEnable(),
            this.loadDataSign()
        ]);
        this.chgSignAcces();
        await this.getAllSignUsers(result[0]);
        await this.getAllSignUsers(result[1]);
    }

    /**
     * загрузка всех подписантов
     */
     private async loadUserSignEnable() {
        this.userSignEnable = false;
        await this.loadMainGu();
        if (!this.mainGuCode) {
            makeToast(this, 'warning', 'Головное предприятие', 'Не получилось определить');
            return;
        }
        const params = JSON.stringify({ abp: this.curAbp.abp, region: this.region, codeModule: ['004.002.002.001'], gu: this.mainGuCode, date_b: this.curVariant.date_start, date_e: this.variantEndDate });
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-user-sign-enable/${encodeURI(params)}`);
            result = await response.json();
            for (const el of result) {
                // --
                let txt = el.position[`name_${this.$i18n.locale}`];
                if (!txt) { txt = el.position.name_ru; }
                el.positionName = txt;
                // --
                el.create_date = null;
                if (el.user_id === this.usrId) { this.userSignEnable = true; }
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-user-sign-enable', (error as Error).toString());
            result = [];
        } finally {
            return result;
        }
    }

    /**
     * получение списка всех подписантов вместе с текущими подписями
     * @param userLst
     */
     private async getAllSignUsers(userLst: any[]) {
        for (const el of userLst) {
            let fl = false;
            for (let i = 0; i < this.signUserLst.length; i++) {
                const elSign = this.signUserLst[i];
                if (elSign.user_id === el.user_id) {
                    fl = true;
                    if (el.create_date) { elSign.create_date = el.create_date; }
                    if (!elSign.name || !elSign.userName) {
                        const nameRes = await this.getUserLoginName(el);
                        elSign.name = nameRes.name;
                        elSign.userName = nameRes.userName;
                    }
                    break;
                }
            }
            if (!fl) {
                const nameRes = await this.getUserLoginName(el);
                el.name = nameRes.name;
                el.userName = nameRes.userName;
                if (!el.positionName) { el.positionName = ''; }
                this.signUserLst.push(el);
            }
        }
        // ---- установка статуса подписания
        let signed = false;
        let notSigned = false;
        for (const el of this.signUserLst) {
            if (el.create_date) {
                signed = true;
            } else {
                notSigned = true;
            }
        }
        if (!signed && notSigned) {
            this.signStatus = { id: 0, name: 'Не подписан' };
        } else if (signed && notSigned) {
            this.signStatus = { id: 1, name: 'Частично подписан' };
        } else if (signed && !notSigned) {
            this.signStatus = { id: 2, name: 'Полностью подписан' };
        }
        // ----
    }

    /**
     * загрузка всех подписей по этим данным
     */
     private async loadDataSign() {
        for (const el of this.signUserLst) {
            el.create_date = null;
        }
        const params = JSON.stringify({
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            deleted: false
        });
        let result: any[] = [];
        try {
            let response: any = await fetch(`/api-py/get-budget_request_form_total_sign/${encodeURI(params)}`);
            response = await response.json();
            for (const el of response) {
                el.create_date = this.dateFormat(new Date(el.create_date));
                result.push({ user_id: el.user_id, create_date: el.create_date });
                if (el.user_id === this.usrId) { this.curUserSigned = true; }
            }
            if (this.curUserSigned === null) { this.curUserSigned = false; }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget_request_form_total_sign', (error as Error).toString());
            result = [];
        } finally {
            return result;
        }
    }


    private chgSignAcces() {
        this.$emit('chgSignAcces', { signOperEnable: this.signOperEnable, userSignEnable: this.userSignEnable, curUserSigned: this.curUserSigned });
    }

    // получить имена и логины пользователей
    private async getUserLoginName(el: any) {
        const obj = { name: '', userName: '' };
        if (el.name || el.userName) { return { name: el.name, userName: el.userName } }
        const result = await this.loadUserName(el.user_id);
        if (result) {
            obj.name = (result.firstName ? `${result.firstName} `: '') + (result.lastName ? result.lastName: '');
            obj.userName = result.username;
        }
        return obj;
    }
    // -----------------

    private dateFormat(date: Date): string {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }

    private async checkGuSigned() {
        const params = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            curYear: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code
        };
        try {
            let response: any = await fetch(`/api-py/get-budget_request_form_total_gu_is_signed/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                response = await response.json();
                if (response.signed) {
                    return true;
                } else {
                    const guTxt = response.guLst.join(', ');
                    makeToast(this, 'warning', 'Не все подписи есть на уровне ГУ', guTxt);
                    return false;
                }
            } else {
                makeToast(this, 'danger', `Ошибка ${response.status} checkGuSigned`, response.statusText);
                return false;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка checkGuSigned', (error as Error).toString());
            return false;
        }
    }

    private startSignClk() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserStartSignModal.showEvent();
    }

    /**
    * подписать
    */
     private async startSign() {
        const guSigned = await this.checkGuSigned();
        if (!guSigned) { return; }
        if (!this.usrName || this.usrName.length < 3) {
            makeToast(this, 'warning', 'Подписание', 'Не удалось получить ИИН пользователя!');
            console.error('Не удалось получить ИИН пользователя!', this.usrName, this.usrId)
            return;
        }
        const iin = this.usrName.slice(3);
        const obj = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            val0: this.totalSumWithoutBip.prog0,
            val1: this.totalSumWithoutBip.prog1,
            val2: this.totalSumWithoutBip.prog2
        };

        const hash: string = await CryptoJS.SHA256(JSON.stringify(obj)).toString();
        let response = null;
        try {
            response = await NCAlayerService.signData(hash, { iin: iin });
            if (response.error) {
                makeToast(this, 'danger', 'Ошибка подписания', response.error);
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка подписания', (error as Error).toString());
            return;
        }
        if (!response.result) { return; }
        const saveObj = Object.assign({
            hash_txt: hash,
            sign_txt: response.result,
            user_id: this.usrId,
            signType: 'abp'
        }, obj);
        let result: any = await fetch('/api-py/save-budget_request_form_total_sign',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'success') {
                makeToast(this, 'success', 'Подписание', 'Данные подписаны');
            } else {
                makeToast(this, 'danger', 'Подписание', result.errTxt);
            }
            // --
            const dtSign = await this.loadDataSign();
            this.chgSignAcces();
            await this.getAllSignUsers(dtSign);
            // --
        } else {
            makeToast(this, 'danger', 'Подписание', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

    private showUserSignModal() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserSignModal.showEvent(this.signUserLst);
    }

    private recalSignModal() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserSignRecalModal.showEvent();
    }

    /**
    * открыть Журнал
    */
     private logSign() {
        const params = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            deleted: null
        };
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserSignLogModal.showEvent(params);
    }

    /**
     * отозвать подпись
     * @param commentTxt
    */
    private async recalClk(commentTxt: string) {
        const saveObj = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            user_id: this.usrId,
            comment_txt: commentTxt
        }

        let result: any = await fetch('/api-py/recal-abp-budget_request_form_total_sign',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'success') {
                makeToast(this, 'success', 'Отзыв подписи', 'Подпись отозвана');
            } else {
                console.error('Отзыв подписи', result)
                makeToast(this, 'danger', 'Отзыв подписи', result.errTxt);
            }
            this.curUserSigned = null;
            // --
            const dtSign = await this.loadDataSign();
            this.chgSignAcces();
            await this.getAllSignUsers(dtSign);
            // --
        } else {
            makeToast(this, 'danger', 'Отзыв подписи', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

}
